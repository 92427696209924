import { memo, useMemo } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import styled from 'styled-components'
import { Virtuoso } from 'react-virtuoso'

import { capitalize } from '../../../utils/misc'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useStickyRefState from '../../../hooks/useStickyRefState'

import TranslateListItem from './TranslateListItem'
import Loading from '../../common/Loading'

const PRIORITY_CATEGORIES = [
  `book`,
  `diagramming`,
  `discourse`,
  `general`,
  `markup`,
  `outline`,
  `phrasing`,
  `projects`,
  `word-study`,
]

const OPTIONAL_CATEGORIES = [
  `admin`,
  `equip`,
]

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ $visible }) => ($visible ? `` : `
    pointer-events: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
  `)}
`

const Header = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  max-width: calc(100vw - 30px);
`

const StyledLoading = styled(Loading)`
  margin-bottom: 10vh;
  z-index: -1;
`

const SearchTextField = styled(TextField)`
  flex: 1;
  min-width: 100px;
`

const HeaderRow = styled.div`
  padding: 12px 0 0;
  font-size: 13px;
`

const FooterSpacer = styled.div`
  height: calc(100vh - 200px);
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`

const MenuHeader = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  text-align: center;
  padding: 10px 0 5px;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};
`

const TranslateList = ({
  // languageId,
  hash,
  setHash,
  i18nEnglishStrings,
  i18nTranslationStringsByHash,
  condensed,
  submitForReview,
  instructionsButton,
  visible,
}) => {

  const [ searchStr, setSearchStr ] = useStickyRefState({ id: `TranslateList:searchStr`, defaultValue: `` })
  const [ category, setCategory ] = useStickyRefState({ id: `TranslateList:category`, defaultValue: `all` })
  const [ type, setType ] = useStickyRefState({ id: `TranslateList:type`, defaultValue: `all` })

  const otherCategories = useMemo(
    () => (
      [ ...new Set((i18nEnglishStrings || []).map(({ category }) => category)) ]
        .filter(category => ![ ...PRIORITY_CATEGORIES, ...OPTIONAL_CATEGORIES ].includes(category))
        .sort()
    ),
    [ i18nEnglishStrings ],
  )

  const goSetSearchStr = useInstanceValuesCallback(({ target }) => setSearchStr(target.value))
  const goSetType = useInstanceValuesCallback(({ target }) => setType(target.value))
  const goSetCategory = useInstanceValuesCallback(({ target }) => setCategory(target.value))

  const filteredI18nTranslationStrings = useMemo(
    () => {

      const getPieces = str => str.toLowerCase().match(/(?:\p{L}+|[0-9]+)+/gu) || []
      const searchStrPieces = getPieces(searchStr)
      
      return (
        (i18nEnglishStrings || [])
          .filter(i18nEnglishString => (
            [ `all`, i18nEnglishString.category ].includes(category)
            && (
              type === `all`
              || (type === `not-translated` && !i18nTranslationStringsByHash[i18nEnglishString.hash])
              || (type === `google-translate-only` && !!i18nTranslationStringsByHash[i18nEnglishString.hash] && i18nTranslationStringsByHash[i18nEnglishString.hash].info.valueHistory.every(({ userId }) => userId === `google-translate`))
              || (type === `human-translated` && !!i18nTranslationStringsByHash[i18nEnglishString.hash] && i18nTranslationStringsByHash[i18nEnglishString.hash].info.valueHistory.some(({ userId }) => userId !== `google-translate`))
              || (type === `has-suggestion` && !!i18nTranslationStringsByHash[i18nEnglishString.hash] && i18nTranslationStringsByHash[i18nEnglishString.hash].info.valueHistory.at(-1).value !== i18nTranslationStringsByHash[i18nEnglishString.hash].info.value)
            )
            && searchStrPieces.every(piece => getPieces(`${i18nEnglishString.engText} ${i18nEnglishString.desc || ``}`).some(word => word.indexOf(piece) === 0))
          ))
          .sort((a,b) => a.engText.toLowerCase() < b.engText.toLowerCase() ? -1 : 1)
          .map(i18nEnglishString => ({
            ...i18nEnglishString,
            i18nEnglishStringInfo: i18nEnglishString.info,
            ...i18nTranslationStringsByHash[i18nEnglishString.hash],
          }))
      )
    },
    [ i18nEnglishStrings, i18nTranslationStringsByHash, searchStr, category, type ],
  )
  

  return (
    <Container $visible={visible}>

      <Header>

        <SearchTextField
          variant="outlined"
          value={searchStr}
          onChange={goSetSearchStr}
          size="small"
          label="Search"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          variant="outlined"
          value={category}
          onChange={goSetCategory}
          select
          size="small"
          label="Category"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="all">
            <b>All categories</b>
          </MenuItem>
          <MenuHeader>High Priority</MenuHeader>
          {PRIORITY_CATEGORIES.map(category => (
            <MenuItem
              key={category}
              value={category}
            >
              {capitalize(category)}
            </MenuItem>
          ))}
          <MenuHeader>Secondary</MenuHeader>
          {otherCategories.map(category => (
            <MenuItem
            key={category}
            value={category}
            >
              {capitalize(category)}
            </MenuItem>
          ))}
          <MenuHeader>Optional</MenuHeader>
          {OPTIONAL_CATEGORIES.map(category => (
            <MenuItem
              key={category}
              value={category}
            >
              {capitalize(category)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          variant="outlined"
          value={type}
          onChange={goSetType}
          select
          size="small"
          label="Status"
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem value="all">
            <b>All statuses</b>
          </MenuItem>
          <MenuItem value="not-translated">
            No translation
          </MenuItem>
          <MenuItem value="google-translate-only">
            Google translate only
          </MenuItem>
          <MenuItem value="human-translated">
            Human translated
          </MenuItem>
          <MenuItem value="has-suggestion">
            Has suggested edit
          </MenuItem>
        </TextField>

      </Header>

      <Virtuoso
        totalCount={filteredI18nTranslationStrings.length}
        itemContent={index => (
          <TranslateListItem
            key={filteredI18nTranslationStrings[index].hash}
            {...filteredI18nTranslationStrings[index]}
            setHash={setHash}
            isSelected={filteredI18nTranslationStrings[index].hash === hash}
          />
        )}
        components={{
          Header: () => (
            <HeaderRow>
              {filteredI18nTranslationStrings.length} item(s)
            </HeaderRow>
          ),
          Footer: () => (
            condensed
              ? (
                <Footer>
                  {instructionsButton}
                  {submitForReview}
                  <FooterSpacer />
                </Footer>
              )
              : <FooterSpacer />
          ),
        }}
        tabIndex={-1}
      />

      {!i18nEnglishStrings && <StyledLoading />}

    </Container>
  )
}


export default memo(TranslateList)