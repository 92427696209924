import { memo, useState, useMemo, useContext } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckIcon from '@material-ui/icons/Check'
import BuildIcon from '@material-ui/icons/Build'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import IconButton from '@material-ui/core/IconButton'
import { getAllLanguages, getLanguageInfo } from '@bibletags/bibletags-ui-helper'
import { useMutation } from '@apollo/client'

import useAppSize from '../../../hooks/useAppSize'
import useModalAnchor from '../../../hooks/useModalAnchor'
import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import { capitalize } from '../../../utils/misc'
import localesInfo from '../../../localesInfo'

import Header from "../../common/Header"
import FadedLoading from '../../common/FadedLoading'
import BasicPopover from '../../common/BasicPopover'

import updateI18nStringMutation from '../../../graphql/mutations/updateI18nString'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

const HeaderText = styled.div`
  font-weight: 300;
  line-height: 48px;
  font-size: 18px;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

const TranslateBiblearc = styled.div`
  font-size: 12px;
  font-weight: 300;
`

const Language = styled.div`
  font-size: 15px;
  text-transform: uppercase;
`

const StyledButton = styled(Button)`
  margin-left: 5px;
`

const MiniHeaderButton = styled(Button)`
  .MuiButton-label {
    flex-direction: column;
    align-items: center;
    text-transform: none;
    line-height: 1.2;
  }
`

const StyledTextField = styled(TextField)`
  margin: 10px 15px;
  width: 300px;
`

const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const StyledMenuItem = styled(MenuItem)`
  ${({ $selected, theme }) => !$selected ? `` : `
    && {
      font-weight: bold;
      opacity: 1;
      background: ${theme.palette.primary.faded}88;
    }
  `}

  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 10px;
  }
`

const Pending = styled.div`
  font-size: 13px;
  font-weight: 300;
  margin-left: 8px;
  color: rgb(0 0 0/.6);
`

const Status = styled.div`
  font-size: 11px;
  pointer-events: none;
  color: rgb(0 0 0/.6);
  font-weight: 300;
  margin: 5px 15px;
  text-transform: uppercase;
`

const statuses = {
  complete: {
    icon: <CheckIcon />,
  },
  partial: {
    icon: <BuildIcon />,
  },
  minimal: {
    icon: <HourglassEmptyIcon />,
  },

}

const TranslateHeader = ({
  languageId,
  setLanguageId,
  status,
  ...otherProps
}) => {

  const { adminLevel } = useContext(LoggedInUserContext) || {}
  const [ updateI18nString ] = useMutation(updateI18nStringMutation)

  const { miniHeaderSize } = useAppSize()
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { anchorEl: anchorEl2, openModal: openModal2, closeModal: closeModal2 } = useModalAnchor()
  const [ saving, setSaving ] = useState(false)

  const { englishName } = languageId ? getLanguageInfo(languageId) : { englishName: `Choose a language` }

  const allLanguages = useMemo(() => getAllLanguages({ iso6391Only: true }).filter(({ iso6391 }) => iso6391 !== `en`), [])

  const onChange = useInstanceValuesCallback(
    (event, { iso6391 }) => {
      setLanguageId(iso6391)
      closeModal()
    }
  )

  const goUpdateStatus = useInstanceValuesCallback(
    async ({ target }) => {
      const status = target.closest(`[data-status]`).getAttribute(`data-status`)
      setSaving(true)
      await updateI18nString({
        variables: {
          languageId,
          hash: `n/a`,
          prevUpdatedAt: 0,
          input: {
            action: `updateStatus`,
            value: status,
          },
        }
      })
      setSaving(false)
      closeModal2()
    }
  )

  const statusButton = (
    <StyledIconButton
      onClick={openModal2}
    >
      {(statuses[status] || {}).icon || null}
    </StyledIconButton>
  )

  return (
    <>

      <Header
        {...otherProps}
        centerExtraComponent={
          miniHeaderSize
          && (
            <>
              <MiniHeaderButton
                onClick={openModal}
              >
                <TranslateBiblearc>
                  Translate Biblearc into
                </TranslateBiblearc>
                <Language>
                  {englishName}
                </Language>
              </MiniHeaderButton>
              {statusButton}
            </>
          )
        }
      >
        <HeaderText>
          Translate Biblearc into
          <StyledButton
            onClick={openModal}
            variant="outlined"
            size="small"
          >
            {englishName}
          </StyledButton>
          {statusButton}
        </HeaderText>
      </Header>

      <BasicPopover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}    
      >
        <Autocomplete
          options={allLanguages}
          getOptionLabel={({ englishName, nativeName }) => (
            nativeName === englishName
              ? englishName
              : `${englishName} (${nativeName})`
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label="Search for a language"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              size="small"
              autoFocus
            />
          )}
          onChange={onChange}
        />
      </BasicPopover>

      <Menu
        anchorEl={anchorEl2}
        open={!!anchorEl2}
        onClose={saving ? null : closeModal2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}    
      >
        <Status>Status</Status>
        {Object.keys(statuses).map(stts => (
          <StyledMenuItem
            key={stts}
            onClick={goUpdateStatus}
            data-status={stts}
            disabled={stts === status || ![ 'ADMIN', 'EDITOR' ].includes(adminLevel)}
            $selected={stts === status}
          >
            {statuses[stts].icon}
            {capitalize(stts)}
            {stts === status && (localesInfo.localeStatus[languageId] || `minimal`) !== stts &&
              <Pending>
                (Pending)
              </Pending>
            }
          </StyledMenuItem>
        ))}
        {saving && <FadedLoading />}
      </Menu>

    </>
  )
}

export default memo(TranslateHeader)